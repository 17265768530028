import revive_payload_client_DfKpHr8ZhZ from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/nuxt@3.12.4_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_@parcel+watcher@2.4.1_@types+node_y2vydagxfjppbpsf2gi5vmnwfy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VkuBjqac1f from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/nuxt@3.12.4_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_@parcel+watcher@2.4.1_@types+node_y2vydagxfjppbpsf2gi5vmnwfy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MEjWK6vv9z from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/nuxt@3.12.4_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_@parcel+watcher@2.4.1_@types+node_y2vydagxfjppbpsf2gi5vmnwfy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_aWrmcEzYwU from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/nuxt@3.12.4_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_@parcel+watcher@2.4.1_@types+node_y2vydagxfjppbpsf2gi5vmnwfy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_H6DqOux5am from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/nuxt@3.12.4_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_@parcel+watcher@2.4.1_@types+node_y2vydagxfjppbpsf2gi5vmnwfy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_2JX6ayv9Pb from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/nuxt@3.12.4_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_@parcel+watcher@2.4.1_@types+node_y2vydagxfjppbpsf2gi5vmnwfy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Y8WLCTD0Rj from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/nuxt@3.12.4_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_@parcel+watcher@2.4.1_@types+node_y2vydagxfjppbpsf2gi5vmnwfy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/getsignalz/getsignalz/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_0SBP3JRLVA from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/nuxt@3.12.4_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_@parcel+watcher@2.4.1_@types+node_y2vydagxfjppbpsf2gi5vmnwfy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_F0TbUYO7go from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_patch_hash=5dcq766eeuesmqdcvoqrnf6tzi_@tanstack+vue-query@5_byi3tgwmd3j425k5aeyetplaka/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import plugin_u8UOJOBTwf from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.7_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_encoding@0.1.13_mag_lrhudujz7fq4huw6m3jo6emzci/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_pdeCEJy77H from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.12.4_@azure+cosmos@4.1.0_@azure+storag_yuccgexbhadw726dlcwg5lqkwi/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import runtime_wNIQkZlad8 from "/home/runner/work/getsignalz/getsignalz/frontend/plugins/runtime.ts";
import timeago_wa2DbggHAx from "/home/runner/work/getsignalz/getsignalz/frontend/plugins/timeago.ts";
import toastification_xLadjaQwvu from "/home/runner/work/getsignalz/getsignalz/frontend/plugins/toastification.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/getsignalz/getsignalz/frontend/plugins/vuetify.ts";
export default [
  revive_payload_client_DfKpHr8ZhZ,
  unhead_VkuBjqac1f,
  router_MEjWK6vv9z,
  payload_client_aWrmcEzYwU,
  navigation_repaint_client_H6DqOux5am,
  check_outdated_build_client_2JX6ayv9Pb,
  chunk_reload_client_Y8WLCTD0Rj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0SBP3JRLVA,
  plugin_F0TbUYO7go,
  plugin_u8UOJOBTwf,
  plugin_pdeCEJy77H,
  runtime_wNIQkZlad8,
  timeago_wa2DbggHAx,
  toastification_xLadjaQwvu,
  vuetify_7h9QAQEssH
]