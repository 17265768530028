import { default as _91_46_46_46slug_93aJiklerZK5Meta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/[...slug].vue?macro=true";
import { default as activateK4kWChEBePMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/activate.vue?macro=true";
import { default as indexWbdprtxx1rMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/admin/index.vue?macro=true";
import { default as indexo8P3alg5ziMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/index.vue?macro=true";
import { default as completedV2hio4qEzMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/pay/complete.vue?macro=true";
import { default as indexZ67qm8qzwjMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/pay/index.vue?macro=true";
import { default as indexZzSjfC4ST5Meta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/playground/index.vue?macro=true";
import { default as optimizeOVpGkUBxarMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/playground/optimize.vue?macro=true";
import { default as portfoliocbIigJllz8Meta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/portfolio.vue?macro=true";
import { default as _91ticker_93HEIXgUCyk2Meta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/securities/[ticker].vue?macro=true";
import { default as indexVXI7WRCWSiMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/securities/index.vue?macro=true";
import { default as settingsooM07sK2NvMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/settings.vue?macro=true";
import { default as subscribeFUVw1GeLYVMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/subscribe.vue?macro=true";
import { default as _91ticket_935AJHcjOAu2Meta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/support/[ticket].vue?macro=true";
import { default as create_45ticketRTvdI8hot3Meta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/support/create-ticket.vue?macro=true";
import { default as indexa8sBF4pGcOMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/support/index.vue?macro=true";
import { default as acceptrveX510cKaMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/terms/accept.vue?macro=true";
import { default as indexFutaKLbqSlMeta } from "/home/runner/work/getsignalz/getsignalz/frontend/pages/terms/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93aJiklerZK5Meta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "activate",
    path: "/activate",
    meta: activateK4kWChEBePMeta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/activate.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexWbdprtxx1rMeta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexo8P3alg5ziMeta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pay-complete",
    path: "/pay/complete",
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/pay/complete.vue").then(m => m.default || m)
  },
  {
    name: "pay",
    path: "/pay",
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/pay/index.vue").then(m => m.default || m)
  },
  {
    name: "playground",
    path: "/playground",
    meta: indexZzSjfC4ST5Meta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/playground/index.vue").then(m => m.default || m)
  },
  {
    name: "playground-optimize",
    path: "/playground/optimize",
    meta: optimizeOVpGkUBxarMeta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/playground/optimize.vue").then(m => m.default || m)
  },
  {
    name: "portfolio",
    path: "/portfolio",
    meta: portfoliocbIigJllz8Meta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/portfolio.vue").then(m => m.default || m)
  },
  {
    name: "securities-ticker",
    path: "/securities/:ticker()",
    meta: _91ticker_93HEIXgUCyk2Meta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/securities/[ticker].vue").then(m => m.default || m)
  },
  {
    name: "securities",
    path: "/securities",
    meta: indexVXI7WRCWSiMeta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/securities/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsooM07sK2NvMeta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "subscribe",
    path: "/subscribe",
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: "support-ticket",
    path: "/support/:ticket()",
    meta: _91ticket_935AJHcjOAu2Meta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/support/[ticket].vue").then(m => m.default || m)
  },
  {
    name: "support-create-ticket",
    path: "/support/create-ticket",
    meta: create_45ticketRTvdI8hot3Meta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/support/create-ticket.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: indexa8sBF4pGcOMeta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-accept",
    path: "/terms/accept",
    meta: acceptrveX510cKaMeta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/terms/accept.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: indexFutaKLbqSlMeta || {},
    component: () => import("/home/runner/work/getsignalz/getsignalz/frontend/pages/terms/index.vue").then(m => m.default || m)
  }
]