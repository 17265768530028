import validate from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/nuxt@3.12.4_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_@parcel+watcher@2.4.1_@types+node_y2vydagxfjppbpsf2gi5vmnwfy/node_modules/nuxt/dist/pages/runtime/validate.js";
import feature_45flag_45global from "/home/runner/work/getsignalz/getsignalz/frontend/middleware/featureFlag.global.ts";
import manifest_45route_45rule from "/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/nuxt@3.12.4_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_@parcel+watcher@2.4.1_@types+node_y2vydagxfjppbpsf2gi5vmnwfy/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  feature_45flag_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/getsignalz/getsignalz/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.7_@azure+cosmos@4.1.0_@azure+storage-blob@12.24.0_encoding@0.1.13_mag_lrhudujz7fq4huw6m3jo6emzci/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}