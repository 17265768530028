/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeApiLayers, initializeSync } from "@effect-app/vue"
import * as Layer from "effect/Layer"
import * as Runtime from "effect/Runtime"
import { Effect, HttpClient, Option } from "@/utils/prelude"
import type { ApiConfig } from "effect-app/client"
import type {} from "resources/lib.js"
import { SentrySdkLive, setupSentry } from "~/utils/observability"
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions"

export const versionMatch = ref(true)

export const runtime = ref<ReturnType<typeof makeRuntime>>()

function makeRuntime(
  feVersion: string,
  env: string,
  isRemote: boolean,
  disableTracing: boolean,
) {
  const apiLayers = Layer.provideMerge(
    Layer.effect(
      HttpClient.HttpClient,
      HttpClient.HttpClient.pipe(
        // Effect.map(
        //   HttpClient.tapRequest(() =>
        //     Effect.annotateCurrentSpan({
        //       "fe.device.id": deviceId,
        //       "fe.user.id": store.user?.id,
        //     }),
        //   ),
        // ),
        Effect.map(
          HttpClient.tap(r =>
            Effect.sync(() => {
              const remoteFeVersion = r.headers["x-fe-version"]
              if (remoteFeVersion) {
                versionMatch.value = feVersion === remoteFeVersion
              }
            }),
          ),
        ),
      ),
    ),
    makeApiLayers({ apiUrl: "/api/api", headers: Option.none() }),
  )

  const otelAttrs = {
    serviceName: "getsignalz-frontend",
    serviceVersion: feVersion,
    attributes: {
      [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: env,
      //["fe.device.id"]: deviceId,
    },
  }

  const rt: {
    runtime: Runtime.Runtime<RT>
    clean: () => void
  } = initializeSync(
    disableTracing
      ? apiLayers
      : apiLayers.pipe(
          Layer.merge(
            isRemote ? SentrySdkLive(otelAttrs, env) : WebSdkLive(otelAttrs),
          ),
        ),
  )
  return {
    ...rt,
    runFork: Runtime.runFork(rt.runtime),
    runSync: Runtime.runSync(rt.runtime),
    runPromise: Runtime.runPromise(rt.runtime),
    runCallback: Runtime.runCallback(rt.runtime),
  }
}

// TODO: make sure the runtime provides these
export type RT = ApiConfig | HttpClient.HttpClient.Default

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()
  const isRemote = config.public.env !== "local-dev"
  // Sentry before observability
  setupSentry(nuxtApp.vueApp, isRemote)
  runtime.value = makeRuntime(
    config.public.feVersion,
    config.public.env,
    isRemote,
    !isRemote && !config.public.telemetry,
  )
})
